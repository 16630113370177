#information.body {
  box-sizing: border-box;
  width: 100%;
  height: 40%;
  background-color: wight;
  border-top: 3px #333333 solid;
  overflow: hidden;
}

/* == */

/*  */
#facility-info.body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
  padding: 5px 10px;
  background-color: white;
}

#facility-info.title {
  width: fit-content;
  height: fit-content;
  font-size: 2.3em;
  font-weight: bold;
  margin: 30px 10px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  text-overflow: ellipsis;
}

#facility-info.cell {
  width: 100%;
  height: 160px;
  margin: 0px 0px;
  flex-shrink: 0;
  background-color: #EEEEEE;
  display: flex;
}

#cell-item.icon-area {
  width: calc(30% - 30px);
  min-width: calc(30% - 30px);
  background-color: white;
  margin: 5px 15px;
  display: flex;
  justify-content: center;

}

#cell-item.icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#cell-item.title-area {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #EEEEEE;
  margin: 10px 10px;
  align-self: center;
}

#cell-item.info {
  font-size: 2.3em;
  font-weight: bold;
  text-align: left;
  position: absolute;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  width: fit-content;
  top: 50%;
  transform: translate(0%, -50%);
}

#cell-item.collapse-mark {
  width: 40px;
  height: 40px;
  position: absolute;
  font-size: 40px;
  left: 100%;
  top: calc(100% - 20px);
  transform: translate(-100%, -100%);
}

#cell-item.detail {
  width: 100%;
  margin: 0px 0px;
  flex-shrink: 0;
  background-color: #EEEEEE;
  display: flex;
}

#cell-item.detail-text {
  width: 100%;
  font-size: 2em;
  background-color: #EEEEEE;
  padding: 15px 20px;
}