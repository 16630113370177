:root {
  --icon-size: 30px;
}

#map.body {
  width: 100%;
  height: calc(60% - 3px);
  background-color: aquamarine;
  border-bottom: 3px #666666 solid;
}

/* == */
#map-tab.body {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  background-color: #AC66A5;
}

#map-tab.cell {
  width: calc(50% - 40px);
  height: calc(100% - 5px);
  margin: 5px 20px 0px 20px;
  cursor: pointer;
  border: 0px;
  font-size: 2em;
  color: white;
  background-color: #AC66A5;
  appearance: none;
  -webkit-appearance: none;
}

#map-tab.cell:disabled {
  border-radius: 20px 20px 0px 0px;
  background-color: #920784;
  cursor: auto;
  border: 0px;
}

/* == */
#category-tab.body {
  width: 100%;
  height: 7%;
  align-items: center;
  display: flex;
  flex-direction: row;
  overflow: auto;
  border-bottom: 2px black solid;
  background-color: white;
}

#category-tab.tab {
  height: 100%;
  font-size: 1.5em;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.category-mark {
  height: 30px;
  width: 30px;
}

.category-name {
  margin-left: 5px;
}

/* == */
#map-scrole.body {
  width: 100%;
  height: 83%;
  position: relative;
  background-color: white;
}

#map-scrole.map {
  position: relative;
  background-color: aliceblue;
}

#floor-selector.panel {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 0px;
  visibility: hidden;
  background-color: #AC66A5;
}

#floor-selector.floor-button {
  border: 0px;
  font-size: 2em;
  margin: 5px;
  padding: 12px 17px;
  color: #FFFFFF;
  background-color: #AC66A5;
  appearance: none;
  -webkit-appearance: none;
}

#floor-selector.floor-button:disabled {
  border-radius: 15px;
  color: #FFFFFF;
  background-color: #920784;
}

.map-marker {
  position: absolute;
  height: var(--icon-size);
  width: var(--icon-size);
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}