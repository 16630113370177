/* バウンススクロールの無効化 */
html {
  position: fixed;
  overflow: hidden;
  -webkit-overflow-scrolling: none;
  overscroll-behavior: none;
}

/* == */
#stadium-map.body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: block;
}

/* -- */

/* -エラーダイアログ */
#dialog.bg-cover {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #00000080;
  z-index: 100;
}

#dialog.server-error {
  position: fixed;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  border: 5px #000000 solid;
  border-radius: 10px 10px;
  left: 50%;
  top: 50%;
  z-index: 110;
}

#dialog.body {
  font-size: 2em;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}